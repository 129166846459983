import Vue from 'vue';
import Vuetify from 'vuetify';

import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import '@fortawesome/fontawesome-free/css/all.css'
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const theme = {
    primary: '#0E4D78',
    secondary: '#DF7205',
    accent: '#DF7205',
    info: '#DF7205',
    success: "#4CAF50",
    warning: "#FFC107"
  }
  
  export default new Vuetify({
    icons: {
      iconfont: ['fa'],
    },
    theme: {
      themes: {
        light: theme,
        dark: theme,
      },
    },
  })