import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from 'axios'
import VueLocalStorage from 'vue-localstorage';
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import VueRouterUserRoles from "vue-router-user-roles";
import vueTippy from 'vue-tippy';
import moment from "moment";
import VueMomentJS from "vue-momentjs";
import VueSweetalert2 from 'vue-sweetalert2';
import ExportarExcel from '@/components/ExportarExcel/ExportarExcel.vue';
import UploadExcelComponent from '@/components/UploadExcel/index.vue';
import {
  funciones_lotes
} from './funciones_lotes.js'
import {
  funciones
} from './funciones.js'
import {
  ServerTable,
  ClientTable,
  Event
} from 'vue-tables-2';


import 'regenerator-runtime/runtime'
import './plugins/base'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import 'sweetalert2/dist/sweetalert2.min.css';
import './plugins/vue-table'
import Vidle from 'v-idle';

Vue.use(Vidle);
Vue.config.productionTip = false



Vue.use(VueRouterUserRoles, {
  router
});
Vue.use(VueLocalStorage, {
  name: 'local_storage',
  bind: true
});
Vue.use(BootstrapVue);
Vue.use(vueTippy);
Vue.use(funciones_lotes);
Vue.use(funciones);
Vue.use(VueMomentJS, moment);
Vue.component('exportarExcel', ExportarExcel);
Vue.component('UploadExcelComponent', UploadExcelComponent);
Vue.use(VueSweetalert2);

window._ = require('lodash');
window.axios = require('axios');
window.moment = require('moment');
window.funciones_lotes = funciones_lotes;
window.funciones = funciones;


let getUser = Promise.resolve({
  role: 'guest'
});
if (Vue.prototype.$local_storage.get('sb_usuario') != "") {
  getUser = Promise.resolve({
    role: Vue.prototype.$local_storage.get('sb_role'),
  });
}

Vue.mixin({
  methods: {
    cerrarSesion() {
      window.localStorage.clear();
      Vue.prototype.$local_storage.set("sb_role", "guest");
      Vue.prototype.$local_storage.set("sb_usuario", "");
      Vue.prototype.$user.set({
        role: "guest"
      });
      window.dialogLoader.showSnackbar('Se cerró sesión correctamente..', {
        color: 'success'
      });
    },
    cerrarSesionInactividad() {
      window.localStorage.clear();
      Vue.prototype.$local_storage.set("sb_role", "guest");
      Vue.prototype.$local_storage.set("sb_usuario", "");
      Vue.prototype.$user.set({
        role: "guest"
      });
      Vue.swal({
        type: 'warning',
        title: '¡Su sesión ha expirado!',
        text: 'Ingrese nuevamente.',
        footer: ''
      });
    },
    verificaPermiso(permiso) {
      return Vue.prototype.$local_storage.get(permiso) == 100;
    },

  }
});

Vue.prototype.$uuid = require('uuid');

window.axios.interceptors.request.use(function(config) {
  if (config.url.includes(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE) || config.url.includes(process.env.VUE_APP_COUCHDB_URL + "/_users/")) {
    config.withCredentials = true;
  }

  if (vm.$route.fullPath != '/facturacion' && vm.$route.fullPath != "/" && vm.$route.fullPath != '/verificador' && vm.$route.fullPath != '/facturas/facturas_globales_robot') {
    vm.verificaSesion(config.url);
  }
  return config;
}, function(error) {
  return Promise.reject(error);
});

window.axios.interceptors.response.use(function(response) {
  return response;
}, function(error) {
  if (error.response.data != null && error.response.data.error != null && error.response.data.reason != null) {
    console.log(error.response.data);
    if (error.response.data.error == "unauthorized" && error.response.data.reason == "You are not authorized to access this db.") {
      //redirect to login y limpiar variables
      vm.redirectLogin();
    }
  }
  return Promise.reject(error);
});

var procesando = 0;

const vm = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  methods: {
    redirectLogin() {
      window.localStorage.clear();
      Vue.prototype.$local_storage.set("sb_role", "guest");
      Vue.prototype.$local_storage.set("sb_usuario", "");
      Vue.prototype.$user.set({
        role: "guest"
      });
      router.push("/", () => {
        Vue.swal({
          type: 'warning',
          title: '¡Su sesión ha expirado!',
          text: 'Ingrese nuevamente al sistema.',
          footer: ''
        });
      });      
    },
    verificaSesion: async function(url) {
      var self = Vue.prototype.$local_storage;
      if (url == 'inactividad')
        setTimeout(vm.reiniciaInactividad, 5 * 60000);
      if (self.get('sb_usuario') != "" && self.get('sb_usuario') != null && (procesando == 0 || url == 'inactividad')) {
        var opcion = 1;
        if (url == 'inactividad')
          opcion = 0;
        else
          procesando = 1;

        //Verificar Sesión Aqui hacer la llamada a la db para verificar
        var tiempo = await vm.get_sesion(opcion);

        if (tiempo == false) {
          vm.cerrarSesion(1);
        } else {
          console.log("ok");
        }

        if (url != 'inactividad')
          setTimeout(vm.reiniciaProcesando, 5 * 60000);
      } else if ((self.get('sb_usuario') == "" || self.get('sb_usuario') == null) && vm.verificaUrlPermitida() == undefined) {
        vm.cerrarSesion(2);
      }
    },
    reiniciaInactividad: function() {
      vm.verificaSesion("inactividad");
    },
    reiniciaProcesando: function() {
      procesando = 0;
    },
    verificaUrlPermitida: function() {
      var x = window.location.pathname;
      //Son las url permitidas para acceso a usuarios invitados
      var permitidas = ["/", "/facturacion", "/verificador"];
      var permitidasConParametros = [];
      var search = permitidas.find(element => element.toLowerCase() == x.toLowerCase());
      if (search == undefined) {
        search = permitidasConParametros.find(element => x.toLowerCase().indexOf(element.toLowerCase()) != -1);
      }

      return search;
    },
    cerrarSesion: function(opcion = 1) {
      window.localStorage.clear();
      Vue.prototype.$local_storage.set("sb_role", "guest");
      Vue.prototype.$local_storage.set("sb_usuario", "");
      Vue.prototype.$user.set({
        role: "guest"
      });
      procesando = 0;
      if (opcion == 1) {
        router.push("/", () => {
          Vue.swal({
            type: 'warning',
            title: '¡Su sesión ha expirado!',
            text: 'Ingrese nuevamente al sistema.',
            footer: ''
          });
        });
      } else {
        router.push("/");
      }
    },
    get_sesion: async function(opcion) {
      var tiempo = 3600 * 3; //Para que sean 3 horas
      let where = {
        type: "user",
        name: Vue.prototype.$local_storage.get('sb_usuario')
      };
      let data = {
        selector: where
      }
      var result = 3;

      await window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + "/_users/_find", data)
        .then(async response => {
          var user = response.data.docs[0];
          if (user != undefined) {
            var ultimo = user.ultimo_movimiento != undefined && user.ultimo_movimiento != null ? user.ultimo_movimiento : 0;
            if (ultimo > 0) {
              ultimo = ultimo / 1000;
            }

            var now = Date.now() / 1000;
            var diff = now - ultimo;
            if (diff > tiempo) {
              result = false;
            } else {
              if (opcion == 1) {
                user.ultimo_movimiento = Date.now();
                await window.axios
                  .put(process.env.VUE_APP_COUCHDB_URL + '/_users/' + user._id + '?conflicts=true', user)
                  .then(response => {
                    //console.log("updated last move");
                  })
                  .catch(error => {
                    //console.log("error last move");
                  });
                result = true;
              }
            }
          } else {
            //console.log("usr undefined");
            result = true;
          }
        })
        .catch(error => {
          //console.log("en el catch");
          result = true;
        });

      return result;
    }
  }
}).$mount('#app')

getUser.then(user => {
  Vue.prototype.$user.set(user);
  vm
});
