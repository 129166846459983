<template>
<base-material-card :icon="icon" class="v-card--material-stats" v-bind="$attrs" v-on="$listeners">
    <template v-slot:after-heading>
        <div class="ml-auto text-right" style=" margin-right:35px">
            <div class="body-5 grey--text overline font-weight-light" v-text="title" style="word-wrap: break-word; " />

            <h3 class="display-2 font-weight-light text--primary">
                <animated-number v-if="value>1" :value="value"></animated-number>
                <span v-if="value<2">{{value}}</span>
            </h3>
            <div>
            <span class=" font-weight-light grey--text overline"  v-if="smallValue>=0">
                {{subtitle}}<br />
                <h3 class="display-2 font-weight-light" :class="subColor">
                <animated-number v-if="smallValue >= 0" :value="smallValue"></animated-number>
               </h3>
            </span>
            </div>
        
          <br v-if="smallValue<0"/>
         <br v-if="smallValue<0"/>
        </div>

    </template>

    <v-col cols="12" class="px-0">
      <div style="margin-top:-50px">
        <v-icon small v-if="iconBtn1!=undefined" v-on:click="clicBtn1($event)" color="secondary">{{ iconBtn1 }}
        </v-icon>
        <span class="overline secondary--text font-weight-light ">{{titleBtn1}} </span>
        <v-icon v-if="iconBtn2!=undefined" :title='titleBtn2' v-on:click="clicBtn2($event)" color="success" size="25" v-tippy>{{ iconBtn2 }}</v-icon>
        </div>
    </v-col>
    <v-btn style="position:absolute; margin-left:-12px; margin-top:130px; z-index:1; height:30px;" block x-small  :color="subTextColor" v-on:click="clicBtnLink($event)" >
        <v-icon  size="16" class="ml-2 mr-1"> {{ subIcon }} </v-icon>{{subText}}
    </v-btn>
</base-material-card>
</template>

<script>
import Card from './Card'
import AnimatedNumber from "@/components/AnimatedNumber.vue";

export default {
    name: 'MaterialStatsCard',
    components: {
        AnimatedNumber
    },
    inheritAttrs: false,

    props: {
        ...Card.props,
        icon: {
            type: String,
            required: true,
        },
        iconBtn1: {
            type: String,
            default: undefined,
        },
        iconBtn2: {
            type: String,
            default: undefined,
        },
        titleBtn1: {
            type: String,
            default: undefined,
        },
        titleBtn2: {
            type: String,
            default: undefined,
        },
        onClickBtn1: Function, // eslint-disable-line      
        onClickBtn2: Function, // eslint-disable-line      
        onClickBtnLink: Function, // eslint-disable-line   
        subIcon: {
            type: String,
            default: undefined,
        },
        subIconColor: {
            type: String,
            default: undefined,
        },
        subTextColor: {
            type: String,
            default: "primary",
        },
        subText: {
            type: String,
            default: undefined,
        },
        subColor: {
            type: String,
            default: undefined,
        },
        title: {
            type: String,
            default: undefined,
        },
        subtitle: {
            type: String,
            default: undefined,
        },
        value: {
            type: Number,
            default: 0,
        },
        smallValue: {
            type: Number,
            default: 0,
        },
        selected: {
            default: false
        },
        id: {
            dafault: 1
        },
    },
    computed: {
        
        
    },

    created() {

    },

    methods: {
        clicBtn1: function (event) {
            if (event) {
                event.preventDefault()
            }
            this.onClickBtn1();
        },
        clicBtn2: function (event) {
            if (event) {
                event.preventDefault()
            }
            this.onClickBtn2();
        },
        clicBtnLink: function (event) {
            if (event) {
                event.preventDefault()
            }
            this.onClickBtnLink(this.id);
        },
    },
}
</script>

<style lang="sass">
.v-card--material-stats
  display: flex
  flex-wrap: wrap
  position: relative

  > div:first-child
    justify-content: space-between

  .v-card
    border-radius: 4px
    flex: 0 1 auto

  .v-card__text
    display: inline-block
    flex: 1 0 calc(100% - 120px)
    position: absolute
    top: 0
    width: 100%
    left: 0

  .v-card__actions
    flex: 1 0 100%
</style>