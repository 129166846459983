import Vue from 'vue'
import {ServerTable, ClientTable, Event} from 'vue-tables-2';
import PaginacionComponent from '@/components/PaginacionComponent.vue';



let options = {
    perPage: 10000000,
    pagination: { show:false},
    compileTemplates: true,
    filterByColumn: true,
    dateFormat: 'YYYY-MM-DD',
    datepickerOptions: {
      showDropdowns: true,
      autoUpdateInput: true,
    },
    texts: {
      filter: "Filtrar:",
      filterBy: 'Filtrar por {column}',
      noResults: "",
      count: '{count} Registros',
      loading: 'Cargando...',
      defaultOption: '',
      columns: 'Columnas',
      page:""
    },
  };
  let useVuex = false;
  let theme = "bootstrap4";
  let template = "default";

  Vue.use(ClientTable, options, useVuex, theme, template);
  Vue.use(ServerTable, options, useVuex, theme, template);
