const centavosFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const funciones = {

    validaCorreo: function (mail) {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(mail);
    },
    enviarEmail:function(data) {
        return new Promise( function(resolve, reject) {
            try {

                window.axios
                .post(process.env.VUE_APP_REPORTES_URL + '/bodeguita/enviar_email/',data)
                .then(response => {
                    return resolve(true);
                })
                .catch(error => {
                    return reject(error);
                });

            } catch (error) {
                return reject(error);
            }
        });

    },

    //Elimina un caracter del inicio y final del string
    trimByChar: function(string, character) {
        const arr = Array.from(string);
        const first = arr.findIndex(char => char !== character);
        const last = arr.reverse().findIndex(char => char !== character);
        return (first === -1 && last === -1) ? '' : string.substring(first, string.length - last);
      },

    //Obtiene consecutivo de Sucursales segun la tabla
    getConsecutivoSucursal: function(data) {
        return new Promise(function(resolve, reject) {

            try {
                var anio = window.moment().format("YYYY");
                var select = {
                    "type":"sucursales",
                    "_id": data.id_sucursal,
                };

                if(data.caja!=null && data.caja!=""){
                    select["caja"] = data.caja;
                }

                if(data.longitud==null || data.longitud==""){
                    data["longitud"] = 5; //default 5
                }

                let search = {
                    "selector": select,
                    //"sort": ["nombre"],
                    limit:1,
                };

            window.axios
                //.post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',search)
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE+ "/" + data.id_sucursal)
                .then(response => {

                    var model = response.data;
                    var incremento = 0;

                    model.autoincrementables.forEach(e => {
                        if(e.tabla == data.tabla && e.anio == anio){
                            e.auto_increment = parseInt(e.auto_increment) + 1;
                            incremento = e.auto_increment;
                        }
                    });


                    window.axios
                      .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + model._id + '?conflicts=true', model)
                      .then(r => {
                            var numero = funciones.leftPad(incremento, data.longitud, "0");
                            return resolve(numero);
                      })
                      .catch(error => {
                        return reject(error);
                      });

                })
                .catch(error => {
                    return reject(error);
                });


            } catch (error) {
                return reject(error);
              }

        });
    },

    //Obtiene consecutivo de Caja, Año y Sucursal
    getConsecutivoCaja: function(data) {
        return new Promise(function(resolve, reject) {

            try {
                var select = {
                    'type': 'autoincrementable_caja', 
                    'id_sucursal': data['id_sucursal'],
                    'anio': parseInt(data['anio']),
                    'caja': data['caja'].toString().toUpperCase()
                };

                if(data.longitud==null || data.longitud==""){
                    data["longitud"] = 8; //default 8
                }

                let search = {
                    "selector": select,                    
                    limit:1,
                    "use_index":"_design/c819de007768959d3507eee9b508c8295042f72b"
                };

                window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',search)
                .then(response => {
                    
                    if(response.data.docs.length > 0){
                        var model = response.data.docs[0];
                        model["folio"] += 1;
                        
                        window.axios
                        .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + model._id + '?conflicts=true', model)
                        .then(r => {
                            var numero = funciones.leftPad(model["folio"], data.longitud, "0");
                            return resolve(numero);
                        })
                        .catch(error => {
                            return reject(error);
                        });
                        
                    } else {//Si no existe se genera nuevo
                        var model = {
                            'type': 'autoincrementable_caja', 
                            'id_sucursal': data['id_sucursal'],
                            'anio': parseInt(data['anio']),
                            'caja': data['caja'].toString().toUpperCase(),
                            'folio': 1
                          };
                
                          window.axios
                            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', model)
                            .then(r => {
                                var numero = funciones.leftPad(model["folio"], data.longitud, "0");
                                return resolve(numero);
                            })
                            .catch(error => {
                                return reject(error);
                            });
                    }

                })
                .catch(error => {
                    return reject(error);
                });


            } catch (error) {
                return reject(error);
              }

        });
    },

    //Obtiene consecutivo de Configuración segun tabla
    getConsecutivo:  function(data) {
        return new Promise(async function(resolve, reject) {

            try {
                var anio = window.moment().format("YYYY");
                var select = {
                    "type":"configuracion"
                };

                if(data.longitud==null || data.longitud==""){
                    data["longitud"] = 5; //default 5
                }

                let search = {
                    "selector": select,
                    "sort": ["type"],
                    limit:1,
                };

            await window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',search)
                .then(async response => {

                    var model = response.data.docs[0];
                    var incremento = 1;
                    for(var k in model.auto_incrementables){
                        if(model.auto_incrementables[k].tabla == data.tabla && model.auto_incrementables[k].anio == anio){
                            incremento = parseInt(model.auto_incrementables[k].auto_increment) + 1;
                        }
                    }

                    let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_configuracion/_update/update_auto_incrementable/" + model._id;
                    let dataAutoIncrementable = {
                        tabla: data.tabla,
                        anio: anio,
                        auto_increment: incremento
                    };

                    await window.axios
                    .post(url, dataAutoIncrementable)
                    .then(response => {
                        var numero = funciones.leftPad(incremento, data.longitud, "0");
                        return resolve(numero);
                    })
                    .catch(error => {
                        return reject(error);
                    });

                })
                .catch(error => {
                    return reject(error);
                });


            } catch (error) {
                return reject(error);
              }

        });
    },
    
    /**
     * Actualiza el campo especificado con el nuevo valor siempre y cuando cumpla con el antiguo valor 
     * @param {*} data //{"campo":"campo a actualziar", "oldvalue":"valor a buscar", "newvalue":"valor a guardar"}
     * @returns bool
     */
    updateArticulos:  function(data) {
        return new Promise(function(resolve, reject) {

            window.axios
            .post(process.env.VUE_APP_REPORTES_URL + "/bodeguita/actualizar_campo_articulos/", data)            
            .then(response => {
                return resolve(true);
            })
            .catch(error => {
                return reject(error);
            });            

        });
    },

    leftPad: function (num, padlen, padchar) {
        var pad_char = typeof padchar !== 'undefined' ? padchar : '0';
        var pad = new Array(1 + parseInt(padlen)).join(pad_char);
        if (num) return (pad + num).slice(-pad.length);
    },


    /**
     * Función para convertir un número a letras, con centavos (ideal para representar dinero). Fuente: https://gist.github.com/alfchee/e563340276f89b22042a
     *
     * @param {float} cantidad - La cantidad a convertir en letras.
     * @param {string} moneda - Moneda opcional para desplegarse en el texto si es que se especifica una. Ej: "PESOS", "DÓLARES"
     *
     */
    numeroALetras: function(cantidad, moneda) {

        var numero = 0;

        cantidad = parseFloat(cantidad);

        if (cantidad == "0.00" || cantidad == "0") {
            return "CERO 00/100 ";
        } else {
            var cantidadConCentavosExplicitos = centavosFormatter.format(parseFloat(cantidad)).toString().split(".");
            var ent = cantidad.toString().split(".");
            var arreglo = funciones.separar_split(ent[0]);
            var longitud = arreglo.length;

            switch (longitud) {
                case 1:
                    numero = funciones.unidades(arreglo[0]);
                    break;
                case 2:
                    numero = funciones.decenas(arreglo[0], arreglo[1]);
                    break;
                case 3:
                    numero = funciones.centenas(arreglo[0], arreglo[1], arreglo[2]);
                    break;
                case 4:
                    numero = funciones.unidadesdemillar(arreglo[0], arreglo[1], arreglo[2], arreglo[3]);
                    break;
                case 5:
                    numero = funciones.decenasdemillar(arreglo[0], arreglo[1], arreglo[2], arreglo[3], arreglo[4]);
                    break;
                case 6:
                    numero = funciones.centenasdemillar(arreglo[0], arreglo[1], arreglo[2], arreglo[3], arreglo[4], arreglo[5]);
                    break;
                case 7:
                    numero = funciones.unidadesdemillon(arreglo[0], arreglo[1], arreglo[2], arreglo[3], arreglo[4], arreglo[5], arreglo[6]);
                    break;
                case 8:
                    numero = funciones.decenasdemillon(arreglo[0], arreglo[1], arreglo[2], arreglo[3], arreglo[4], arreglo[5], arreglo[6], arreglo[7]);
                    break;
                case 9:
                    numero = funciones.centenasdemillon(arreglo[0], arreglo[1], arreglo[2], arreglo[3], arreglo[4], arreglo[5], arreglo[6], arreglo[7], arreglo[8]);
                    break;
                default:
                    numero = "________________________________________________";
                    break;
            }

            cantidadConCentavosExplicitos[1] = isNaN(cantidadConCentavosExplicitos[1]) ? '00' : cantidadConCentavosExplicitos[1];

            if (cantidad == "1000000" && numero == "UN MILLÓN MIL ") {
                numero = "UN MILLÓN ";
            }

            var divisibleEntreUnMillon = parseInt(cantidad) % 1000000;

            if (divisibleEntreUnMillon == 0) {
                numero = numero.replace("MILLONES MIL", "MILLONES");
            }

            if (moneda) {
                if (cantidad == "1000000" && numero == "UN MILLÓN ") {
                    numero = "UN MILLÓN DE";
                }
                if (divisibleEntreUnMillon == 0 && parseInt(cantidad) > 1000000) {
                    numero = numero.replace("MILLONES", "MILLONES DE");
                }
                return numero + " " + moneda + " " + cantidadConCentavosExplicitos[1] + "/100";
            } else {
                return numero +" "+ cantidadConCentavosExplicitos[1] + "/100";
            }
        }
    },

    unidades: function(unidad) {
        var unidades = Array('UN ', 'DOS ', 'TRES ', 'CUATRO ', 'CINCO ', 'SEIS ', 'SIETE ', 'OCHO ', 'NUEVE ');
        return unidades[unidad - 1];
    },

    decenas: function(decena, unidad) {
        var diez = Array('ONCE ', 'DOCE ', 'TRECE ', 'CATORCE ', 'QUINCE', 'DIECISEIS ', 'DIECISIETE ', 'DIECIOCHO ', 'DIECINUEVE ');
        var decenas = Array('DIEZ ', 'VEINTE', 'TREINTA', 'CUARENTA', 'CINCUENTA', 'SESENTA', 'SETENTA', 'OCHENTA', 'NOVENTA');
        var veinte = "VEINTE";
        if (decena == 0 && unidad == 0) {
            return "";
        }

        if (decena == 0 && unidad > 0) {
            return funciones.unidades(unidad);
        }

        if (decena == 1) {
            if (unidad == 0) {
                return decenas[decena - 1];
            } else {
                return diez[unidad - 1];
            }
        } else if (decena == 2) {
            if (unidad == 0) {
                return decenas[decena - 1];
            }
            else if (unidad == 1) {
                return veinte = "VEINTI" + "UN ";
            }
            else {
                return veinte = "VEINTI" + funciones.unidades(unidad);
            }
        } else {

            if (unidad == 0) {
                return decenas[decena - 1] + " ";
            }
            if (unidad == 1) {
                return decenas[decena - 1] + " Y " + "UN ";
            }

            return decenas[decena - 1] + " Y " + funciones.unidades(unidad);
        }
    },

    centenas: function(centena, decena, unidad) {
        var centenas = Array("CIENTO ", "DOSCIENTOS ", "TRESCIENTOS ", "CUATROCIENTOS ", "QUINIENTOS ", "SEISCIENTOS ", "SETECIENTOS ", "OCHOCIENTOS ", "NOVECIENTOS ");

        if (centena == 0 && decena == 0 && unidad == 0) {
            return "";
        }
        if (centena == 1 && decena == 0 && unidad == 0) {
            return "CIEN ";
        }

        if (centena == 0 && decena == 0 && unidad > 0) {
            return funciones.unidades(unidad);
        }

        if (decena == 0 && unidad == 0) {
            return centenas[centena - 1] + "";
        }

        if (decena == 0) {
            var numero = centenas[centena - 1] + "" + funciones.decenas(decena, unidad);
            return numero.replace(" Y ", " ");
        }
        if (centena == 0) {

            return funciones.decenas(decena, unidad);
        }

        return centenas[centena - 1] + "" + funciones.decenas(decena, unidad);

    },

    unidadesdemillar: function(unimill, centena, decena, unidad) {
        var numero = funciones.unidades(unimill) + "MIL " + funciones.centenas(centena, decena, unidad);
        numero = numero.replace("UN MIL ", "MIL ");
        if (unidad == 0) {
            return numero.replace(" Y ", " ");
        } else {
            return numero;
        }
    },

    decenasdemillar: function(decemill, unimill, centena, decena, unidad) {
        var numero = funciones.decenas(decemill, unimill) + "MIL " + funciones.centenas(centena, decena, unidad);
        return numero;
    },

    centenasdemillar: function(centenamill, decemill, unimill, centena, decena, unidad) {

        var numero = 0;
        numero = funciones.centenas(centenamill, decemill, unimill) + "MIL " + funciones.centenas(centena, decena, unidad);

        return numero;
    },

    unidadesdemillon: function(unimillon, centenamill, decemill, unimill, centena, decena, unidad) {
        var numero = funciones.unidades(unimillon) + "MILLONES " + funciones.centenas(centenamill, decemill, unimill) + "MIL " + funciones.centenas(centena, decena, unidad);
        numero = numero.replace("UN MILLONES ", "UN MILLÓN ");
        if (unidad == 0) {
            return numero.replace(" Y ", " ");
        } else {
            return numero;
        }
    },

    decenasdemillon: function(decemillon, unimillon, centenamill, decemill, unimill, centena, decena, unidad) {
        var numero = funciones.decenas(decemillon, unimillon) + "MILLONES " + funciones.centenas(centenamill, decemill, unimill) + "MIL " + funciones.centenas(centena, decena, unidad);
        return numero;
    },

    centenasdemillon: function(centenamillon, decemillon, unimillon, centenamill, decemill, unimill, centena, decena, unidad) {

        var numero = 0;
        numero = funciones.centenas(centenamillon, decemillon, unimillon) + "MILLONES " + funciones.centenas(centenamill, decemill, unimill) + "MIL " + funciones.centenas(centena, decena, unidad);

        return numero;
    },

    separar_split: function(texto) {
        var contenido = new Array();
        for (var i = 0; i < texto.length; i++) {
            contenido[i] = texto.substr(i, 1);
        }
        return contenido;
    }


}//export
