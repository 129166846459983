<template>
<div :id="idName" @click="generate">
    <slot>
        Download {{name}}
    </slot>
</div>
</template>

<script>
import XLSX from 'xlsx';

export default {
    props: {
        // Json to descargar
        data: {
            type: Array,
            required: false,
            default: null
        },
        // Campos del JSON que se quieren exportar
        // Si no se configura, todos los campos del json se exportarán
        exportFields: {
            type: Object,
            required: false
        },
        // Valor por default para los campos vacíos
        defaultValue: {
            type: String,
            required: false,
            default: ""
        },
        // Nombre del archivo a exportar
        name: {
            type: String,
            default: "Exportar.xlsx"
        },
        // Nombre de la hoja
        worksheet: {
            type: String,
            default: "Sheet1"
        },
        // Para multiples hojas
        tipo: {
            type: Number,
            default:1
        }
    },
    computed: {
        // Identificador unico del componente
        idName() {
            var now = new Date().getTime();
            return "export_" + now;
        },

        downloadFields() {
            if (this.exportFields !== undefined) return this.exportFields;
        }
    },
    methods: {
        async generate() {
            if (this.tipo == 1) {
                let data = this.data;
                if (!data || !data.length) {
                    return;
                }

                let json = this.getProcessedJson(data, this.downloadFields);

                var ws = XLSX.utils.json_to_sheet(json);
                var wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, this.worksheet);
                XLSX.writeFile(wb, this.name);
            } else {
                let data = this.data;
                if (!data || !data.length) {
                    return;
                }

                var wb = XLSX.utils.book_new();

                for(let x in data[0]) {
                    if(data[0][x].length == 0) {
                        var json = [{"": "No hay Información"}];
                    } else {
                        var json = this.getProcessedJson(data[0][x], this.downloadFields);
                    }
                    var ws = XLSX.utils.json_to_sheet(json);
                    XLSX.utils.book_append_sheet(wb, ws, x.charAt(0).toUpperCase() + x.slice(1) );
                }
                XLSX.writeFile(wb, this.name);
            }

        },
        /*

getProcessedJson

---------------

Get only the data to export, if no fields are set return all the data

*/
        getProcessedJson(data, header) {
            let keys = this.getKeys(data, header);
            let newData = [];
            let _self = this;
            data.map(function (item, index) {
                let newItem = {};
                for (let label in keys) {
                    let property = keys[label];
                    newItem[label] = _self.getValue(property, item);
                }
                newData.push(newItem);
            });

            return newData;
        },
        getKeys(data, header) {
            if (header) {
                return header;
            }

            let keys = {};
            for (let key in data[0]) {
                keys[key] = key;
            }
            return keys;
        },

        getValue(key, item) {
            const field = typeof key !== "object" ? key : key.field;
            let indexes = typeof field !== "string" ? [] : field.split(".");
            let value = this.defaultValue;

            if (!field)
                value = item;
            else if (indexes.length > 1)
                value = this.getValueFromNestedItem(item, indexes);
            else
                value = this.parseValue(item[field]);

            if (key.hasOwnProperty('callback'))
                value = this.getValueFromCallback(value, key.callback);

            return value;
        },

        getValueFromNestedItem(item, indexes) {
            let nestedItem = item;
            for (let index of indexes) {
                if (nestedItem)
                    nestedItem = nestedItem[index];
            }
            return this.parseValue(nestedItem);
        },

        getValueFromCallback(item, callback) {
            if (typeof callback !== "function")
                return this.defaultValue
            const value = callback(item);
            return this.parseValue(value);
        },
        parseValue(value) {
            return value || value === 0 || typeof value === 'boolean' ?
                value :
                this.defaultValue;
        },

    } // end methods
};
</script>
