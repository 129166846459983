const arr_campos_lotes = ["id_sucursal","id_articulo","cantidad","precio_compra","fecha_entrada"];
const arr_campos_ajuste = ["id_sucursal","id_articulo","cantidad"];

export const funciones_lotes = {

    getMes: function(mesString) {
        var mes = parseInt(mesString);
        const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        if (meses[mes] != undefined)
          return meses[mes];
        else
          return "N/A";
      },


    //PARA REGISTRAR ENTRADA DE INVENTARIO
        //Nota, se busca por sucursal, articulo, compra, precio_compra, fecha entrada, fecha caducidad(opcional)
        //si encuentra un registro con todo eso aumenta la cantidad, sino crea un registro nuevo
    nuevaEntradaInventario: function(data) {
        return new Promise(function(resolve, reject) {

            try {
                for(var key in arr_campos_lotes){
                    if(data[arr_campos_lotes[key]] == null || data[arr_campos_lotes[key]] == "")
                        return reject("campo "+arr_campos_lotes[key]+" es requerido");
                }

                if(data["fecha_caducidad"] == null)
                    data["fecha_caducidad"] = "";
                else
                    data["fecha_caducidad"] = (data["fecha_caducidad"]!=null && data["fecha_caducidad"]!="") ? window.moment(data["fecha_caducidad"]).format("YYYY-MM-DDTHH:mm:ss") : "";

                data["id_compra"] = data["id_compra"]!=null ? data["id_compra"] : "";
                data["fecha_entrada"] = (data["fecha_entrada"]!=null && data["fecha_entrada"]!="") ? window.moment(data["fecha_entrada"]).format("YYYY-MM-DDTHH:mm:ss") : "";
                data["actual"] = parseFloat(data.cantidad);
                data["comprometido"] = 0;
                data["type"] = "lotes";

                //Buscar entrada con los datos, si existe regresar el id para editarlo, sino crear nuevo
                //(21-marzo-2024): Se comenta para que ya no buscque y pierda tiempo, sino que ya inserte directamente uno nuevo
                /*funciones_lotes.buscarEntrada(data).then(result =>{
                    if(result == null){ */
                        //Registro nuevo

                        window.axios
                        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', data)
                        .then(response => {
                            //console.log(response);
                            return resolve([{"lote":response.data.id,"cantidad":data.cantidad,
                                    "precio_compra":data.precio_compra, "fecha_caducidad":data.fecha_caducidad}]);
                        })
                        .catch(error => {
                            return reject(error);
                        });

                    /*} else {
                        //Editar cantidad

                        result.cantidad += parseFloat(data.cantidad);
                        result.actual += parseFloat(data.cantidad);

                        window.axios
                        .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + result._id + '?conflicts=true', result)
                        .then(response => {
                            //console.log(response);
                            return resolve([{"lote":response.data.id,"cantidad":result.cantidad,
                                    "precio_compra":result.precio_compra, "fecha_caducidad":result.fecha_caducidad}]);
                        })
                        .catch(error => {
                            return reject(error);
                        });

                    } 
                }).catch(err => {
                    return reject(err);
                }); */


            } catch (error) {
                return reject(error);
              }

        });
    },

    //Registrar entrada en bulk
    nuevaEntradaInventarioBulk: async function(datas) {
        return new Promise(async function(resolve, reject) {
            try {

                for(var k in datas){
                    var data = datas[k];

                    if(data["fecha_caducidad"] == null)
                        data["fecha_caducidad"] = "";
                    else
                        data["fecha_caducidad"] = (data["fecha_caducidad"]!=null && data["fecha_caducidad"]!="") ? window.moment(data["fecha_caducidad"]).format("YYYY-MM-DDTHH:mm:ss") : "";

                    data["fecha_entrada"] = (data["fecha_entrada"]!=null && data["fecha_entrada"]!="") ? window.moment(data["fecha_entrada"]).format("YYYY-MM-DDTHH:mm:ss") : "";
                    data["actual"] = parseFloat(data.cantidad);
                    data["comprometido"] = 0;
                    data["type"] = "lotes";
                }

                var datasUnicos = funciones_lotes.getDatasUnico(datas);

                for(var k in datasUnicos){
                    var data = datasUnicos[k];

                    //Buscar entrada con los datos, si existe regresar el id para editarlo, sino crear nuevo
                    /*await funciones_lotes.buscarEntrada(data).then(result =>{
                        if(result != null){
                            //Editar cantidad y actual
                            data["cantidad"] += result.cantidad;
                            data["actual"] += result.actual;
                            data["_id"] = result._id;
                            data["_rev"] = result._rev;
                        }
                    }).catch(err => {
                        return reject(err);
                    });*/
                }

                var docs = {"docs": datasUnicos};
                window.axios
                        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_bulk_docs', docs)
                        .then(response => {
                            var lotes = [];
                            for(var k in datasUnicos){
                                var record = {"lote":response.data[k].id,"cantidad":datasUnicos[k].cantidad,
                                    "precio_compra":datasUnicos[k].precio_compra, "fecha_caducidad":datasUnicos[k].fecha_caducidad}
                                lotes.push(record);
                            }
                            return resolve(lotes);
                        })
                        .catch(error => {
                            return reject(error);
                        });

            } catch (error) {
                return reject(error);
              }

        });
    },

    //PARA AUMENTAR/DISMINUIR CANTIDAD EN LOTE
            //NOTA: Si el id_compra es diferente de null, va a buscar esa compra para afectarla directamente
            // sino ya buscara por fecha_caducidad (si el producto tiene), y fecha de entrada (la menor que todavía tenga actual>0)
        ajusteInventario: async function(data) {
            return new Promise(function(resolve, reject) {

                try {
                    for(var key in arr_campos_ajuste){
                        if(data[arr_campos_ajuste[key]] == null || data[arr_campos_ajuste[key]] == "")
                            return reject("campo "+arr_campos_ajuste[key]+" es requerido");
                    }

                    funciones_lotes.buscarLote(data).then(result =>{
                        if(result == null){
                            reject("No se encontró lote que afectar. Favor de verificar");
                        } else {
                            //Afectar inventario
                            var lotesBulk = [];
                            var esComprometido=0;
                            var lotesAfectados = [];
                            for(var key in result){

                                var cantidadFloat = parseFloat(data.cantidad);
                                if(cantidadFloat > 0){ //Si es positivo se incrementa el actual
                                    if(data.id_compra != null && data.id_compra != "") //Si trae id_compra se incremente la cantidad también
                                        result[key].lote.cantidad += cantidadFloat;
                                    result[key].lote.actual += cantidadFloat;
                                } else { //Si es negativo se disminuye la cantidad cubierta por el lote(viene negativa) y solo se disminuye el actual

                                    if (data.es_super == 1){//Si es super se afecta directamente el actual
                                        result[key].lote.actual += parseFloat(result[key].cantidad);
                                    } else {//Agregamos inventario comprometido
                                        //Si es negativo se disminuye la cantidad cubierta por el lote(viene negativa) y solo se disminuye el actual
                                        esComprometido=1;
                                        result[key].lote.actual += parseFloat(result[key].cantidad);
                                        if(result[key].lote.comprometido != null)
                                            result[key].lote.comprometido += parseFloat(result[key].cantidad); //Lo guardamos negativo
                                        else
                                            result[key].lote.comprometido = parseFloat(result[key].cantidad); //Lo guardamos negativo
                                    }
                                }

                                delete result[key].lote.fecha_ordena;
                                //console.log("result",result[key]);
                                lotesBulk.push(result[key].lote);
                                lotesAfectados.push({"lote":result[key].lote._id,"cantidad":result[key].cantidad, "es_comprometido":esComprometido,
                                        "precio_compra":result[key].lote.precio_compra, "fecha_caducidad":result[key].lote.fecha_caducidad });

                            }
                            var docs = {"docs": lotesBulk};
                            window.axios
                                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_bulk_docs', docs)
                                    .then(response => {
                                        return resolve(lotesAfectados);
                                    })
                                    .catch(error => {
                                        return reject(error);
                                    });

                        }
                    }).catch(err => {
                        return reject(err);
                    });


                } catch (error) {
                    return reject(error);
                  }

            });
        },

        //Ajuste directo al inventario, sin necesidad de que exista ya una entrada en lotes
        //Se utiliza solo en el modulo de Ajustes a inventario
        ajusteInventarioDirecto: async function(data) {
            return new Promise(function(resolve, reject) {

                try {
                    for(var key in arr_campos_ajuste){
                        if(data[arr_campos_ajuste[key]] == null || data[arr_campos_ajuste[key]] == "")
                            return reject("campo "+arr_campos_ajuste[key]+" es requerido");
                    }

                    funciones_lotes.buscarLote(data).then(result =>{
                        if(result == null){
                            var dataEntrada = {
                                id_sucursal: data.id_sucursal,  //Requerido
                                id_articulo: data.id_articulo,  //Requerido
                                cantidad: data.cantidad, //Requerido positivo
                                precio_compra: data.precio_compra, //Requerido
                                fecha_caducidad: "", //Opcional
                                fecha_entrada: data.fecha_entrada,  //Requerido
                                origen: data["origen"]!=null ? data["origen"] : "",
                                usuario: data["usuario"]!=null ? data["usuario"] : "",
                            }
                            funciones_lotes.nuevaEntradaInventario(dataEntrada).then(resultEntrada => {
                                return resolve(resultEntrada);
                            }).catch(errEntrada => {
                                return reject(errEntrada);
                            });
                        } else {
                            //Afectar inventario
                            var lotesBulk = [];
                            var esComprometido=0;
                            var lotesAfectados = [];
                            for(var key in result){

                                var cantidadFloat = parseFloat(data.cantidad);
                                if(cantidadFloat > 0){ //Si es positivo se incrementa el actual
                                    if(data.id_compra != null && data.id_compra != "") //Si trae id_compra se incremente la cantidad también
                                        result[key].lote.cantidad += cantidadFloat;
                                    result[key].lote.actual += cantidadFloat;
                                } else { //Si es negativo se disminuye la cantidad cubierta por el lote(viene negativa) y solo se disminuye el actual

                                    if (data.es_super == 1){//Si es super se afecta directamente el actual
                                        result[key].lote.actual += parseFloat(result[key].cantidad);
                                    } else {//Agregamos inventario comprometido
                                        //Si es negativo se disminuye la cantidad cubierta por el lote(viene negativa) y solo se disminuye el actual
                                        esComprometido=1;
                                        result[key].lote.actual += parseFloat(result[key].cantidad);
                                        if(result[key].lote.comprometido != null)
                                            result[key].lote.comprometido += parseFloat(result[key].cantidad); //Lo guardamos negativo
                                        else
                                            result[key].lote.comprometido = parseFloat(result[key].cantidad); //Lo guardamos negativo
                                    }
                                }

                                delete result[key].lote.fecha_ordena;
                                //console.log("result",result[key]);
                                lotesBulk.push(result[key].lote);
                                lotesAfectados.push({"lote":result[key].lote._id,"cantidad":result[key].cantidad, "es_comprometido":esComprometido,
                                        "precio_compra":result[key].lote.precio_compra, "fecha_caducidad":result[key].lote.fecha_caducidad });

                            }
                            var docs = {"docs": lotesBulk};
                            window.axios
                                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_bulk_docs', docs)
                                    .then(response => {
                                        return resolve(lotesAfectados);
                                    })
                                    .catch(error => {
                                        return reject(error);
                                    });

                        }
                    }).catch(err => {
                        return reject(err);
                    });


                } catch (error) {
                    return reject(error);
                  }

            });
        },

    parse_date(date) {
        if (!date) return null;
        return window.moment(String(date)).format("YYYY-MM-DD");
    },

    buscarEntrada: function(data){
        return new Promise(function(resolve, reject) {
            var fechaCOrta = data.fecha_entrada.toString().substring(0,10);
            let consulta = {
                "selector": {
                    "type": "lotes",
                    "id_sucursal": data.id_sucursal,
                    "id_articulo": data.id_articulo,
                    "id_compra": data.id_compra,
                    "precio_compra":data.precio_compra,
                    "fecha_entrada": {"$regex": "(?i)" +  fechaCOrta},
                    "fecha_caducidad":data.fecha_caducidad,
                    "actual": {"$gt": 0}
                },
                "sort": [
                    "id_sucursal","id_articulo"
                ],
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',consulta)
                .then(response => {
                    if (response.data.docs.length > 0){
                        //console.log("SI ENCONTRADA");
                        return resolve(response.data.docs[0]);
                    }else{
                        //console.log("NO ENCONTRADA");
                        return resolve(null);
                    }

                })
                .catch(error => {
                    return reject(error);
                });
        });
    },
    buscarLote: function(data){
        return new Promise(function(resolve, reject) {
            try{

                var consulta = {};
                
                consulta = {
                    "selector": {
                        "type": "lotes",
                        "fecha_caducidad":{"$exists":true},
                        //"id_sucursal": data.id_sucursal,
                        "id_articulo": data.id_articulo,
                        "actual": {"$gt": 0}
                    },
                    //"sort": ["id_sucursal","id_articulo"],
                    "use_index":"_design/62213d099d9a000230f0a1505d1ceac0af7a983b"
                };
                
                var esSuper = 0;
                if(data.es_super != null && parseInt(data.es_super) == 1){
                    esSuper = 1;
                }

                window.axios
                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',consulta)
                    .then(response => {
                        if(data.id_compra != null && data.id_compra != ""){
                            response.data.docs = response.data.docs.filter(e => e.id_sucursal == data.id_sucursal && e.id_compra == data.id_compra);
                        } else {
                            response.data.docs = response.data.docs.filter(e => e.id_sucursal == data.id_sucursal);
                        }
                        
                        if (response.data.docs.length > 0){
                            //console.log("SI ENCONTRADA");

                            response.data.docs.forEach(e => {
                                e.fecha_ordena = (e.fecha_caducidad==null || e.fecha_caducidad=="") ? e.fecha_entrada : e.fecha_caducidad;
                            });
                            //Ordenar por fecha_ordena (ya se toma en cuenta la fecha_caducidad)
                            response.data.docs.sort(function (a, b) {
                                //Ordenar por fecha más antigüa
                                return new Date(a.fecha_ordena) - new Date(b.fecha_ordena);
                            });

                            //Si la cantidad es positiva(aumenta) solo se regresa el primero
                            if(parseFloat(data.cantidad)>0){
                                return resolve([{"lote":response.data.docs[0], "cantidad":data.cantidad}]);
                            } else {

                                //Si la cantidad es negativa(disminuye) la cantidad se tiene que ajustar con los n registros necesarios que completen la cantidad
                                //por ejemplo si se disminuye 10 y el primer registro tiene 3 y el segundo tiene 10,
                                //entonces se regresan el primero con 3 a disminuir y el segundo con 7 a disminuir
                                var cantidadOriginal = parseFloat(data.cantidad)*-1; //Hacemos positivo para operar
                                var lotesAfectados = [];
                                for(var k in response.data.docs){
                                    var e = response.data.docs[k];

                                    if(cantidadOriginal>0 && cantidadOriginal>=e.actual){//Si hay menos de lo que se quiere disminuir se disminuye lo que hay
                                        lotesAfectados.push({"lote":e, "cantidad":e.actual*-1});
                                        cantidadOriginal = cantidadOriginal - e.actual;
                                    } else if(cantidadOriginal>0 && cantidadOriginal<=e.actual){//Si hay mas o igual a lo que se quiere disminuir se disminuye el total
                                        lotesAfectados.push({"lote":e, "cantidad":cantidadOriginal*-1});
                                        cantidadOriginal = 0;
                                    }
                                    if(cantidadOriginal == 0)
                                        break;
                                };

                                if(cantidadOriginal!= 0 && esSuper==1){//Si es super, se descuenta aunque quede negativo el actual
                                    //Se descuenta el faltante al ultimo lote, no importa si queda negativo
                                    lotesAfectados[lotesAfectados.length-1].cantidad -= cantidadOriginal;
                                    return resolve(lotesAfectados);
                                }else if(cantidadOriginal!= 0 && esSuper==0){//Si no es super, se manda error
                                    reject("No hay suficiente inventario para descontar");
                                } else
                                    return resolve(lotesAfectados);
                            }

                        }else{
                            //console.log("NO ENCONTRADA");
                            return resolve(null);
                        }

                    })
                    .catch(error => {
                        return reject(error);
                    });
            } catch(e){
                return reject(e);
            }
            });
    },

    getUltimoPrecioCompra: function(data){
        return new Promise(function(resolve, reject) {
            try{
                var today = window.moment().format("YYYY-MM-DD");
                const [year_de, month_de, day_de] = today.split("-");
                //const [year_a, month_a, day_a] = this.fecha_a_venta.split("-");

                let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/compras/_view/compras_grupo';
                let urlConditions = `?descending=true&startkey=["${data.id_articulo}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${data.id_articulo}", "{}", "{}", "{}"]`;

                axios.get(url + urlConditions)
                .then(response => {
                    if (response.data.rows.length > 0) {
                        let respuesta = response.data.rows;
                        return resolve(respuesta[0].value.precio_proveedor);
                    } else {
                        return resolve(null);
                    }
                })
                .catch(error => {
                    return reject(error);
                });

                /*var consulta = {
                    "selector": {
                        "type": "lotes",
                        "id_articulo": data.id_articulo,
                        "id_compra": {"$ne": ""},
                    },
                    "sort": [{"fecha_entrada": "desc"}],
                    "limit":1
                };

                window.axios
                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',consulta)
                    .then(response => {
                        if (response.data.docs.length > 0){
                            //console.log("SI ENCONTRADA");
                            return resolve(response.data.docs[0].precio_compra);
                        }else{
                            return resolve(null);
                        }

                    }).catch(error => {
                        return reject(error);
                    }); */
            } catch(e){
                return reject(e);
            }
        });
    },

    consultaExistencia: function(data){
        return new Promise(function(resolve, reject) {
            try{

                if(data.id_articulo == null || data.id_articulo == "")
                    return reject("campo id_articulo es requerido");


                var urlConditions = "&startkey=[\""+data.id_articulo+"\"]&endkey=[\""+data.id_articulo+"\",{}]";

                if(data.id_sucursal != null && data.id_sucursal != "")
                    urlConditions = "&startkey=[\""+data.id_articulo+"\",\""+data.id_sucursal+"\"]&endkey=[\""+data.id_articulo+"\",\""+data.id_sucursal+"\"]";

                window.axios
                    .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/lotes/_view/existencia_articulo?group_level=2'+urlConditions)
                    .then(response => {
                        if(response.data!=null && response.data.rows!=null){
                            var result = [];
                            response.data.rows.forEach(element => {
                                result.push({"id_articulo":element.key[0],"id_sucursal":element.key[1], "existencia":element.value})
                            });
                            return resolve(result);
                        } else {
                            return resolve([]);
                        }
                    })
                    .catch(err => {
                        return reject(err);
                    });

            } catch(e){
                return reject(e);
            }

        });
    },
    consultaExistenciaTotal: function(data){
        return new Promise(function(resolve, reject) {
            try{

                if(data.id_articulo == null || data.id_articulo == "")
                    return reject("campo id_articulo es requerido");

                window.axios
                    .get(process.env.VUE_APP_COUCHDB_URL+ '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/lotes/_view/existencia_total?group=true&key="'+data.id_articulo+'"')
                    .then(response => {
                        var existencia = 0;
                        if(response.data!=null && response.data.rows!=null && response.data.rows.length>0){
                            existencia = response.data.rows[0].value;
                            return resolve(existencia);
                        } else {
                            return resolve(existencia);
                        }
                    })
                    .catch(err => {
                        return reject(err);
                    });

            } catch(e){
                return reject(e);
            }

        });
    },
    consultaExistenciaArticulos: function(){
        return new Promise(function(resolve, reject) {
            try{

                window.axios
                    .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/lotes/_view/existencia_articulo?group=true')
                    .then(response => {
                        if(response.data!=null && response.data.rows!=null){
                            var result = [];
                            //console.log(response.data.rows);
                            response.data.rows.forEach(element => {
                                result.push({"id_articulo":element.key[0],"id_sucursal":element.key[1], "existencia":element.value})
                            });
                            return resolve(result);
                        } else {
                            return reject("No se encontraron existencias");
                        }
                    })
                    .catch(err => {
                        return reject(err);
                    });

            } catch(e){
                return reject(e);
            }

        });
    },
    consultaExistenciaHistorico: function(data, fecha){
        return new Promise(function(resolve, reject) {
            try{
                if (fecha == null || fecha == "")
                  return reject("error_fecha");

                if(data.id_articulo == null || data.id_articulo == "")
                    return reject("campo id_articulo es requerido");


                var urlConditions = "&startkey=[\""+data.id_articulo+"\",\""+fecha+"\"]&endkey=[\""+data.id_articulo+"\",\""+fecha+"\",{}]";

                if(data.id_sucursal != null && data.id_sucursal != "")
                    urlConditions = "&startkey=[\""+data.id_articulo+"\",\""+fecha+"\",\""+data.id_sucursal+"\"]&endkey=[\""+data.id_articulo+"\",\""+fecha+"\",\""+data.id_sucursal+"\"]";

                window.axios
                    .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/lotes_historico/_view/existencia_articulo?group_level=2'+urlConditions)
                    .then(response => {
                        if(response.data!=null && response.data.rows!=null){
                            var result = [];
                            response.data.rows.forEach(element => {
                                result.push({"id_articulo":element.key[0],"id_sucursal":element.key[1], "existencia":element.value})
                            });
                            return resolve(result);
                        } else {
                            return reject("No se encontraron existencias");
                        }
                    })
                    .catch(err => {
                        return reject(err);
                    });

            } catch(e){
                return reject(e);
            }

        });
    },
    getCantidadLote:function(lotes, idLote){
        var filter = lotes.find( e => { return e.lote==idLote });
        if(filter)
            return filter.cantidad;
        else
            return 0;
    },

    getLotesUnicos: function(lotes){
        var lotesResumen = {};
        var lotesUnicosArr = [];
        lotes.forEach(e => {
            if(lotesResumen[e.lote] != null){
                lotesResumen[e.lote] += e.cantidad;
            } else {
                lotesResumen[e.lote] = e.cantidad;
            }
        });
        for (const lote in lotesResumen) {
            lotesUnicosArr.push({"lote":lote, "cantidad":lotesResumen[lote]});
        }
        return lotesUnicosArr;
    },

    getDatasUnico: function(datas){
        var datasResumen = {};
        var datasUnicosArr = [];
        datas.forEach(e => {
            var key = e.fecha_caducidad.toString() + e.fecha_entrada.toString() + e.id_articulo +
                        e.id_compra.toString() + e.id_sucursal + e.precio_compra.toString();
            if(datasResumen[key] != null){
                datasResumen[key].cantidad += e.cantidad;
            } else {
                datasResumen[key] = e;
            }
        });
        for (var k in datasResumen) {
            datasUnicosArr.push(datasResumen[k]);
        }
        return datasUnicosArr;
    },

    //Resta el comprometido de los lotes enviados, esto es cuando se finaliza la venta
    finalizarComprometido: function(lotes){
        return new Promise(function(resolve, reject) {

            try {
                //Sumamos los lotes repetidos, para dejar solo uno y su cantidad total
                var lotesUnicosArr = funciones_lotes.getLotesUnicos(lotes);

                var lotesAfectados = [];
                var lotes_finales = [];
                funciones_lotes.getLotes(lotesUnicosArr).then(results =>{
                    for(var key in results){
                        var cantidadDescuento = parseFloat(funciones_lotes.getCantidadLote(lotesUnicosArr, results[key]._id));
                        results[key].comprometido += (cantidadDescuento*-1); //Lo hacemos positivo para restar el comprometido que se guarda negativo
                        lotesAfectados.push({"lote":results[key]._id, "cantidad":cantidadDescuento,
                                        "precio_compra":results[key].precio_compra, "fecha_caducidad":results[key].fecha_caducidad});
                    }

                    var docs = {"docs": results};
                    window.axios
                        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_bulk_docs', docs)
                        .then(async response => {

                            var lotes_no_afectados = [];
                            var registros_con_error = [];

                            for(var k in response.data){
                                var res = response.data[k];
                                if (res.id && res.error && res.error == "conflict"){ //Si el trae conflict significa que hubo algun error o conflicto, se manda de nuevo
                                    registros_con_error.push(res.id);
                                }
                            }
                            
                            if (registros_con_error.length > 0){
                                //los no afectados volverlos a enviar
                                for(var kl in lotesAfectados){
                                    if (registros_con_error.includes(lotesAfectados[kl]["lote"])){
                                        lotes_no_afectados.push({"lote": lotesAfectados[kl]["lote"], "cantidad": lotesAfectados[kl]["cantidad"]})
                                    } else {
                                        lotes_finales.push(lotesAfectados[kl])
                                    }                                    
                                }
                                
                                if (lotes_no_afectados.length > 0){                                    
                                    await funciones_lotes.finalizarComprometido(lotes_no_afectados).then(result =>{              
                                        lotes_finales = lotes_finales.concat(result);
                                    });
                                    
                                }                                
                            }
                                
                            if(lotes_finales.length > 0){//Significa que hubo conflictos al actualizar algun lote y se tuvo que reenviar a finalizar
                                return resolve(lotes_finales); 
                            } else {
                                return resolve(lotesAfectados);
                            }
                            
                                    
                        })
                        .catch(error => {
                            return reject(error);
                        });

                }).catch(err => {
                    return reject(err);
                });

            } catch(e){
                return reject(e);
            }

        });

    },

    //regresa el comprometido al actual de los lotes enviados, esto es cuando se cancela la venta
    cancelarComprometido: function(lotes){
        return new Promise(function(resolve, reject) {

            try {
                //Sumamos los lotes repetidos, para dejar solo uno y su cantidad total
                var lotesUnicosArr = funciones_lotes.getLotesUnicos(lotes);

                var lotesAfectados = [];
                var lotes_finales = [];
                funciones_lotes.getLotes(lotesUnicosArr).then(results =>{
                    for(var key in results){
                        var cantidadDescuento = parseFloat(funciones_lotes.getCantidadLote(lotesUnicosArr, results[key]._id));
                        results[key].comprometido += (cantidadDescuento * -1); //Se descuenta positivo porque el comprometido es negativo
                        results[key].actual += (cantidadDescuento * -1); //Se suma positivo porque el actual es positivo
                        lotesAfectados.push({"lote":results[key]._id, "cantidad":cantidadDescuento,
                                        "precio_compra":results[key].precio_compra, "fecha_caducidad":results[key].fecha_caducidad});
                    }

                    var docs = {"docs": results};
                    window.axios
                        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_bulk_docs', docs)
                        .then(response => {
                            var lotes_no_afectados = [];
                            var registros_con_error = [];

                            for(var k in response.data){
                                var res = response.data[k];
                                if (res.id && res.error && res.error == "conflict"){ //Si el trae conflict significa que hubo algun error o conflicto, se manda de nuevo
                                    registros_con_error.push(res.id);
                                }
                            }
                            
                            if (registros_con_error.length > 0){
                                //los no afectados volverlos a enviar
                                for(var kl in lotesAfectados){
                                    if (registros_con_error.includes(lotesAfectados[kl]["lote"])){
                                        lotes_no_afectados.push({"lote": lotesAfectados[kl]["lote"], "cantidad": lotesAfectados[kl]["cantidad"]})
                                    } else {
                                        lotes_finales.push(lotesAfectados[kl])
                                    }                                    
                                }
                                
                                if (lotes_no_afectados.length > 0){                                    
                                    funciones_lotes.cancelarComprometido(lotes_no_afectados).then(result =>{              
                                        lotes_finales = lotes_finales.concat(result);
                                    });
                                    
                                }                                
                            }
                                
                            if(lotes_finales.length > 0){//Significa que hubo conflictos al actualizar algun lote y se tuvo que reenviar a finalizar
                                return resolve(lotes_finales); 
                            } else {
                                return resolve(lotesAfectados);
                            }
                        })
                        .catch(error => {
                            return reject(error);
                        });

                }).catch(err => {
                    return reject(err);
                });

            } catch(e){
                return reject(e);
            }

        });
    },

    //Obtiene los documentos, de un listado de lotes {"lote":"", "cantidad":X}
    getLotes: function(lotes){
        return new Promise(function(resolve, reject) {
            var keys = [];
            var results = [];
            lotes.forEach(e => {
                keys.push(e.lote);
            });

            var search = {"keys":keys};
            window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_all_docs?include_docs=true', search)
            .then(response => {

                if(response.data != null && response.data.rows.length>0){
                    response.data.rows.forEach( e => {
                        if(e.doc != null){
                            results.push(e.doc);
                        }
                    });
                    if(results.length>0)
                        return resolve(results);
                    else
                        return reject("Lotes no encontrados");
                } else {
                    return reject("Lotes no encontrados");
                }
            })
            .catch(err => {
                //console.log(err);
                return reject(err);
            });
        });


    },

    //Actualizar precio de compra al articulo, cuando se finaliza la entrada de una factura de compra
    actualizar_precio_compra_articulos: function(id_compra){
        const pattern = /^[\d]*(\.{0,1}[\d]*)$/;

        window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_compra)
        .then(async response => {
            var factura = response.data;
            for(var ka in factura.articulos){
                var value = factura.articulos[ka]["precio_proveedor"].toString();
                //Primero checamos si trae precio proveedor, sino lo cambiamos por el precio factura
                if (value && !pattern.test(value)) {
                    value = factura.articulos[ka]["precio_factura"].toString();
                    factura.articulos[ka]["precio_proveedor"] = factura.articulos[ka]["precio_factura"];
                } else if (value && parseFloat(value) <= 0) {
                    value = factura.articulos[ka]["precio_factura"].toString();
                    factura.articulos[ka]["precio_proveedor"] = factura.articulos[ka]["precio_factura"];
                }

                if (value && !pattern.test(value)) {
                    continue;
                } else if (value && parseFloat(value) <= 0) {
                    continue;                                                       
                } else if(value) {
                    var url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/actualizar_precio_compra/" + factura.articulos[ka]["id_articulo"];

                    var data = {
                        precio_compra: parseFloat(factura.articulos[ka]["precio_proveedor"]), //Se manda el precio final del proveedor
                        impuesto_iva: parseFloat(factura.articulos[ka]["iva"])
                    };

                    await window.axios
                    .post(url, data)
                    .then(async response2 => {
                        if (response2.data == true) {
                            //console.log("Actualizado: ",factura.articulos[ka]["nombre_articulo"]);                                                            
                            //buscar si el articulo tiene equivalencias si es asi, actualizar su precio de compra también
                            await funciones_lotes.actualiza_precio_compra_equivalencias(factura.articulos[ka]["id_articulo"], factura.articulos[ka]["precio_proveedor"], factura.articulos[ka]["iva"]);
                            
                        } else {
                            console.log("No Actualizado: ",factura.articulos[ka]["nombre_articulo"]);
                        }
                    })
                    .catch(error => {
                        console.log("ERROR al actualizar precio de compra: ", error);
                        //console.log("Error: ",factura.articulos[ka]["nombre_articulo"]);
                    });
                }

                //Agregar articulo a proveedor
                funciones_lotes.asociar_articulo_proveedor(factura.articulos[ka]["id_articulo"], factura.proveedor);
                                                    
            }
        })
        .catch(error => {
            console.log("ERROR al obtener compra: ", error);
        });
    },

    actualiza_precio_compra_equivalencias:async function(id_articulo, precio_proveedor, iva_padre){
        var busca = encodeURI(escape(id_articulo));
        await window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/equivalencias?key=\"' + busca + '\"')
        .then(async responseEquivalencias => {
            if (responseEquivalencias.data != null && responseEquivalencias.data.rows != null && responseEquivalencias.data.rows.length > 0) {
                for(var ke in responseEquivalencias.data.rows){
                    var equivalente = responseEquivalencias.data.rows[ke].value;
                    var precio_equivalente = parseFloat(precio_proveedor) / parseFloat(equivalente["cantidad"]);
                    precio_equivalente = parseFloat(precio_equivalente.toFixed(4));
                    var dataEquivalente = {
                        precio_compra: precio_equivalente, //Se manda el precio final del proveedor entre la cantidad equivalente
                        impuesto_iva: parseFloat(iva_padre),
                    };

                    await window.axios
                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/actualizar_precio_compra/" + equivalente["id_articulo_equivalente"], 
                            dataEquivalente)
                    .then(async response3 => {
                        if (response3.data == true) {
                            //console.log("Equivalente Actualizado: ",equivalente["nombre_articulo_equivalente"]);
                        }else {
                            console.log("Equivalente No Actualizado: ",equivalente["nombre_articulo_equivalente"]);
                        }
                    }).catch(errorequiv => {
                        console.log("ERROR al actualizar equivalencia: ", errorequiv);
                        console.log("Error al actualizar equivalencia: ",equivalente["nombre_articulo_equivalente"]);
                    });

                    await funciones_lotes.actualiza_precio_compra_equivalencias(equivalente["id_articulo_equivalente"], precio_equivalente, iva_padre);

                }
            }
        })
        .catch(error => {
            console.log("ERROR al actualizar equivalencia2: ", error);                                                
        });
    },

    asociar_articulo_proveedor: function(id_articulo, proveedor){
        try {
            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/agregar_proveedor/" + id_articulo;
            let data = {
                id_proveedor: proveedor.id_proveedor,
                nombre_proveedor: proveedor.nombre_proveedor,
            };

            window.axios
                .post(url, data)
                .then(response => {
                    if (response.data == true) {
                        //console.log("Articulo: "+id_articulo+" Agregado a proveedor correctamente");                                                
                    } else {
                        //console.log("Error Articulo: "+id_articulo+" no se agregó a proveedor");
                    }
                }).catch(error => {
                    console.log("Exception Articulo: "+id_articulo+": "+error);
                });
        } catch (error) {
            console.log("Error en asociar_articulo_proveedor: ", error);
        }
    },

}//export
