import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 54, 95, .8), rgba(0, 54, 95, .8)',
    barImage: "/static/sidebar-1.png",
    drawer: null,
    current_url: 0,
    datetime: 0,
    avatar: undefined,
    sucursal: 0,
    files: [],
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },
    SET_SCRIM(state, payload) {
      state.barColor = payload
    },
    updateFiles(state, files) {
      state.files = files
    }
  },
  actions: {

  },
})
