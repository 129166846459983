<template>
<v-app>
    <template>
        <dashboard-core-app-bar v-if="!$route.meta.public" v-model="expandOnHover" :key="refresca"/>
        <dashboard-core-drawer v-if="!$route.meta.public" :expand-on-hover.sync="expandOnHover" />
        <v-main>
            <router-view />
        </v-main>
        <dialog-loader ref="dialogLoader"></dialog-loader>
       
    </template>
</v-app>
</template>

<script>


import DialogLoader from "@/components/DialogLoader";

export default {
    components: {
        DialogLoader,
        DashboardCoreAppBar: () => import("@/components/layout/AppBar"),
        DashboardCoreDrawer: () => import("@/components/layout/Drawer"),
    },
    name: "App",
    data: () => ({

        fab: false,
        expandOnHover: true,
        mostrarmenu: false,
        colortema: false,
        expanded: true,
        rightDrawer: false,
        
    }),
    computed: {

        refresca() {
            return this.$store.state.sucursal;
        } 
      
    },

    mounted() {
        window.dialogLoader = this.$refs.dialogLoader;
    },
    created() {
        window.getApp = this;
    },
    methods: {
       
    }
};
</script>

<style scoped>
.sc-header {
    min-height: 50px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    padding: 5px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
    position: relative;
    box-sizing: border-box;
    display: flex;
}

.sc-header--title {
    align-self: center;
    padding: 5px;
    flex: 1;
    user-select: none;
    font-size: 12px;
}

.sc-header--title.enabled {
    cursor: pointer;
    border-radius: 5px;
}
</style>
