<template>
  <div>
    <input
      v-show="!showIcon"
      :id="idInput"
      :ref="idInput"
      type="file"
      accept=".xlsx, .xls"
      @change="handleClick"
    />
    <v-btn
      v-show="showIcon"
      :loading="loading"
      style="margin-left:1px;"
      @click="handleUpload"
      fab :small="smallIcon"
      dark
      x-small
      color="green darken-2"
      :title="title"
      v-tippy
    >
      <v-icon dark>{{icon}}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  props: {
    beforeUpload: Function, // eslint-disable-line
    onSuccess: Function,// eslint-disable-line
    smallIcon: false, //tamaño del icono
    title: {//titulo del icono
      type: String,
      required: false,
      default: 'Importar Plantilla'
    },
    idInput: {//titulo del icono
      type: String,
      required: false,
      default: 'excel-upload-input'
    },
    icon: {//icono
      type: String,
      required: false,
      default: 'cloud_upload'
    },
    showIcon: {//Mostrar icono o mostrar boton 'Examinar'
      type: Boolean,
      required: false,
      default: true
    },
    firstRow: {//Renglón donde comienzan los datos, Si comienzan en el renglón 4, se le restará 1 por el renglón de encabezado
      type: Number,
      required: false,
      //[JEMM]: SE QUITA EL SEGUNDO RENGLON; PARA QUE EMPIECE A LEER DESDE EL TERCERO. SOLICITO LOURDES 19/08/2019
      default: 3
    },    
    headerRow: {//Renglón donde está el encabezado, por default en el renglón 1
      type: Number,
      required: false,
      default: 1
    },    
    headersExcel: {
      type: Array,
      required: false,
      default: null
    },    
  },
  data() {
    return { fab: false,
      name: "UploadExcel",
      loading: false,
      excelData: {
        header: null,
        results: null
      }
    };
  },
  methods: {
    reset() {
      const input = this.$refs[this.idInput];
      if (input != undefined) {
        input.type = 'text'
        input.type = 'file'
      }
      
    },
    generateData({ header, results }) {     
      var margen = 2; //Se restan 2 (1 del encabezado y otro de la fila donde comienza)
      if (this.headersExcel != null)
          margen = 1; //Si se manda encabezado, se resta solo 1 ya que no toma por default la primer linea 
      
      //[JEMM]: SE AGREGA PARAMETRO firstRow PARA INDICAR DESDE DONDE COMIENZAN LOS DATOS. 23/10/2019
      results.splice(0,(this.firstRow-margen)); 
      this.excelData.header = header;
      this.excelData.results = results;
      this.onSuccess && this.onSuccess(this.excelData);
    },
    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.loading) return;
      const files = e.dataTransfer.files;
      if (files.length !== 1) {
        this.$message.error("Only support uploading one file!");
        return;
      }
      const rawFile = files[0]; // only use files[0]

      if (!this.isExcel(rawFile)) {
        this.$message.error(
          "Only supports upload .xlsx, .xls, .csv suffix files"
        );
        return false;
      }
      this.upload(rawFile);
      e.stopPropagation();
      e.preventDefault();
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = "copy";
    },
    handleUpload() {
      document.getElementById(this.idInput).click();
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      if (!rawFile) return;
      this.upload(rawFile);      
    },
    upload(rawFile) {
      //this.$refs[this.idInput].value = null; // fix can't select the same excel                
      this.reset();
      if (!this.beforeUpload) {
        this.readerData(rawFile);
        return;
      }
      const before = this.beforeUpload(rawFile);
      if (before) {
        this.readerData(rawFile);
      }
    },
    readerData(rawFile) {
      //window.dialogLoader.show('Espere un momento por favor..');
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => {
          const data = e.target.result;
          const fixedData = this.fixData(data);
          const workbook = XLSX.read(btoa(fixedData), { type: "base64" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet);                    
          const results = XLSX.utils.sheet_to_json(worksheet, {header:this.headersExcel});          
          this.generateData({ header, results });
          //window.dialogLoader.hide();
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    fixData(data) {
      let o = "";
      let l = 0;
      const w = 10240;
      for (; l < data.byteLength / w; ++l)
        o += String.fromCharCode.apply(
          null,
          new Uint8Array(data.slice(l * w, l * w + w))
        );
      o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
      return o;
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      let C;
      //[JEMM]: SE AGREGA PARAMETRO headerRow PARA INDICAR EL RENGLÓN DONDE ESTÁN LOS ENCABEZADOS. 23/10/2019
      const R = range.s.r + (this.headerRow - 1);      
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        /* find the cell in the first row */
        let hdr = "DESCONOCIDO " + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }

      return headers;
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name);
    }
  }
};
</script>

<style scoped>
#excel-upload-input {
  /*display: none;*/
  z-index: -9999;
}
#drop {
  border: 2px dashed #bbb;
  width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}
</style>
