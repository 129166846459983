<template>
<infinite-loading @infinite="props.infiniteHandler" :distance="distance" ref="infiniteLoading" spinner="default">
  <span slot="no-results" class="m-t-lg">
    <div class="alert alert-warning">
      No se encontraron registros.
    </div>
  </span>

  <div slot="no-more" class="m-t-xs m-b-xs">
    <span class="text-info">
      Total de registros: {{props.total_registros}}
    </span>
  </div>
</infinite-loading>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
export default {
  name: "VtPagination",
  components: {
    InfiniteLoading,
  },
  props: ['props'],
  data() {
    return {
      distance: 100
    }},
}
</script>
