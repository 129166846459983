import Vue from 'vue';
import Router from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import store from './store.js';



Vue.use(Router);

let router = new Router({
  mode: 'history',
  routes: [{
      path: "*",
      meta: {
        public: true
      },
      redirect: {
        path: "/404"
      }
    },

    {
      path: "/404",
      meta: {
        public: true
      },
      name: "NotFound",
      component: () =>
        import(
          /* webpackChunkName: "routes" */
          `@/views/Error.vue`
        )
    },
    {
      path: "/403",
      meta: {
        public: true
      },
      name: "AccessDenied",
      component: () =>
        import(
          /* webpackChunkName: "routes" */
          `@/views/Error.vue`
        )
    },
    {
      path: "/500",
      meta: {
        public: true
      },
      name: "ServerError",
      component: () =>
        import(
          /* webpackChunkName: "routes" */
          `@/views/Error.vue`
        )
    },
    {
      path: "/",
      meta: {
        public: true,
        permissions: [{
            role: "guest",
            access: true,
            redirect: 'login'
          },
          {
            role: "admin",
            access: false,
            redirect: 'index'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "login",
      component: () =>
        import(
          /* webpackChunkName: "routes" */
          `@/views/Login.vue`
        )
    },
    {
      path: "/usuarios/change",
      meta: {
        public: false,
        permissions: [{
          role: "guest",
          access: false,
          redirect: 'login'
        }, ]
      },
      name: "change",
      component: () =>
        import(
          `@/views/configuracion/Change.vue`
        )
    },
    {
      path: "/utilerias/perfiles",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "perfiles",
      component: () =>
        import(
          `@/views/utilerias/Perfiles.vue`
        )
    },
    {
      path: "/catalogos/categorias",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "categorias",
      component: () =>
        import(
          `@/views/catalogos/Categorias.vue`
        )
    },
    {
      path: "/precios/plantillas",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "plantillas",
      component: () =>
        import(
          `@/views/precios/PlantillasPrecios.vue`
        )
    },
    {
      path: "/inventario/equivalencias",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "equivalencias",
      component: () =>
        import(
          `@/views/inventario/ArticulosEquivalencias.vue`
        )
    },
    {
      path: "/utilerias/almacenes",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "almacenes",
      component: () =>
        import(
          `@/views/utilerias/Almacenes.vue`
        )
    },
    {
      path: "/utilerias/sucursales",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "sucursales",
      component: () =>
        import(
          `@/views/utilerias/Sucursales.vue`
        )
    },
    {
      path: "/catalogos/sat",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "/catalogos/sat",
      component: () =>
        import(
          `@/views/catalogos/sat/Principal.vue`
        )
    },
    {
      path: "/catalogos/articulos",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "articulos",
      component: () =>
        import(
          `@/views/catalogos/Articulos.vue`
        )
    },
    {
      path: "/catalogos/empleados",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "empleados",
      component: () =>
        import(
          `@/views/catalogos/Empleados.vue`
        )
    },
    {
      path: "/catalogos/clientes",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "clientes",
      component: () =>
        import(
          `@/views/catalogos/Clientes.vue`
        )
    },
    {
      path: "/utilerias/tipos_precio",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "tipos_precio",
      component: () =>
        import(
          `@/views/utilerias/TiposPrecio.vue`
        )
    },
    {
      path: "/utilerias/impuestos",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "impuestos",
      component: () =>
        import(
          `@/views/utilerias/Impuestos.vue`
        )
    },
    {
      path: "/catalogos/proveedores",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "proveedores",
      component: () =>
        import(
          `@/views/catalogos/Proveedores.vue`
        )
    },
    {
      path: "/catalogos/ciudades",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "ciudades",
      component: () =>
        import(
          `@/views/catalogos/Ciudades.vue`
        )
    },
    {
      path: "/utilerias/configuracion",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "configuracion",
      component: () =>
        import(
          `@/views/configuracion/Configuracion.vue`
        )
    },
    {
      path: "/catalogos/marcas",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "marcas",
      component: () =>
        import(
          `@/views/catalogos/MarcasModelos.vue`
        )
    },
    {
      path: "/catalogos/descripciones",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "descripciones",
      component: () =>
        import(
          `@/views/catalogos/Descripciones.vue`
        )
    },
    {
      path: "/catalogos/articulos_proveedores",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "articulos_proveedores",
      component: () =>
        import(
          `@/views/catalogos/ArticulosProveedores.vue`
        )
    },
    {
      path: "/utilerias/usuarios",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "usuarios",
      component: () =>
        import(
          `@/views/utilerias/Usuarios.vue`
        )
    },
    {
      path: "/utilerias/supervisores",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "supervisores",
      component: () =>
        import(
          `@/views/utilerias/Supervisores.vue`
        )
    },
    {
      path: "/lotes/ejemplo",
      meta: {
        public: false,
        permissions: [{
          role: "guest",
          access: false,
          redirect: 'login'
        }, ]
      },
      name: "/lotes/ejemplo",
      component: () =>
        import(
          `@/views/lotes/EjemploLotes.vue`
        )
    },
    {
      path: "/inventario/ajustes",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "admin",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "/inventario/ajustes",
      component: () =>
        import(
          `@/views/inventario/AjusteInventario.vue`
        )
    },
    {
      path: "/inventario/ajustes_cremeria",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "/inventario/ajustes_cremeria",
      component: () =>
        import(
          `@/views/inventario/CremeriaAjustes.vue`
        )
    },
    {
      path: "/inventario/ajustes_forrajes",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "/inventario/ajustes_forrajes",
      component: () =>
        import(
          `@/views/inventario/ForrajesAjustes.vue`
        )
    },
    {
      path: "/inventario/salidas",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "admin",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "/inventario/salidas",
      component: () =>
        import(
          `@/views/inventario/SalidaSucursal.vue`
        )
    },
    {
      path: "/inventario/entradas",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "admin",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "/inventario/entradas",
      component: () =>
        import(
          `@/views/inventario/EntradasSucursal.vue`
        )
    },
    {
      path: "/inventario/salida_traspaso",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "admin",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "/inventario/salida_traspaso",
      component: () =>
        import(
          `@/views/inventario/SalidaSucursal.vue`
        )
    },
    {
      path: "/inventario/entrada_traspaso",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "admin",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "/inventario/entrada_traspaso",
      component: () =>
        import(
          `@/views/inventario/EntradasSucursal.vue`
        )
    },
    {
      path: "/inventario/traspasos",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "admin",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "/inventario/traspasos",
      component: () =>
        import(
          `@/views/inventario/Traspasos.vue`
        )
    },
    {
      path: "/inventario/traspasos_recibidos",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "admin",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "/inventario/traspasos_recibidos",
      component: () =>
        import(
          `@/views/inventario/TraspasosRecibidos.vue`
        )
    },
    {
      path: "/inventario/desgranar",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "admin",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "/inventario/desgranar",
      component: () =>
        import(
          `@/views/inventario/Desgranar.vue`
        )
    },
    {
      path: "/inventario/merma",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "admin",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "/inventario/merma",
      component: () =>
        import(
          `@/views/inventario/CremeriaMerma.vue`
        )
    },
    {
      path: "/inventario/merma_forrajes",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "admin",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "/inventario/merma_forrajes",
      component: () =>
        import(
          `@/views/inventario/ForrajesMerma.vue`
        )
    },
    {
      path: "/precios/solicitud",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "/precios/solicitud",
      component: () =>
        import(
          `@/views/precios/Etiquetas.vue`
        )
    },
    {
      path: "/compras/ordenes",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "/compras/ordenes",
      component: () =>
        import(
          `@/views/compras/Compras.vue`
        )
    },
    {
      path: "/compras/indicadores",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "/compras/indicadores",
      component: () =>
        import(
          `@/views/compras/IndicadoresCompras.vue`
        )
    },
    {
      path: "/compras/por_pagar",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "/compras/por_pagar",
      component: () =>
        import(
          `@/views/compras/ComprasPorPagar.vue`
        )
    },
    {
      path: "/compras/sello",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "/compras/sello",
      component: () =>
        import(
          `@/views/compras/SelloFactura.vue`
        )
    },
    {
      path: "/compras/subastas",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "/compras/subastas",
      component: () =>
        import(
          `@/views/compras/Subastas.vue`
        )
    },

    {
      path: "/index",
      meta: {
        public: false,
        permissions: [{
          role: "guest",
          access: false,
          redirect: 'login'
        }, ]
      },

      name: "index",
      component: () =>
        import(
          /* webpackChunkName: "routes" */
          `@/views/Index.vue`
        )
    },
    {
      path: "/inventario/stock",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "admin",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "stock",
      component: () =>
        import(
          `@/views/inventario/Stock.vue`
        )
    },
    {
      path: "/utilerias/formaspago",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "formaspago",
      component: () =>
        import(
          `@/views/utilerias/FormasPago.vue`
        )
    },
    {
      path: "/inventario/actual",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "admin",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "inventario_actual",
      component: () =>
        import(
          `@/views/inventario/InventarioActual.vue`
        )
    },
    {
      path: "/inventario/cargar",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "admin",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "cargar_inventario",
      component: () =>
        import(
          `@/views/inventario/CargarInventario.vue`
        )
    },
    {
      path: "/inventario/historico",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "admin",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "inventario_historico",
      component: () =>
        import(
          `@/views/inventario/InventarioHistorico.vue`
        )
    },
    {
      path: "/inventario/conteo",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "admin",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "inventario_conteo",
      component: () =>
        import(
          `@/views/inventario/Conteo.vue`
        )
    },
    {
      path: "/inventario/comprometido",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "inventario_comprometido",
      component: () =>
        import(
          `@/views/inventario/InventarioComprometido.vue`
        )
    },
    {
      path: "/inventario/faltantes",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "admin",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "inventario_faltantes",
      component: () =>
        import(
          `@/views/inventario/Faltantes.vue`
        )
    },
    {
      path: "/inventario/escanear",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "admin",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "inventario_escanear",
      component: () =>
        import(
          `@/views/inventario/Escanear.vue`
        )
    },
    {
      path: "/precios/ajustar",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "ajustar_precios",
      component: () =>
        import(
          `@/views/precios/AjustarPreciosMasivo.vue`
        )
    },
    {
      path: "/precios/ajustar_articulo",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "ajustar_precios_articulo",
      component: () =>
        import(
          `@/views/precios/AjustarPrecioArticulo.vue`
        )
    },
    {
      path: "/facturacion",
      meta: {
        public: true
      },
      name: "app_mobil",
      component: () =>
        import(
          /* webpackChunkName: "routes" */
          `@/views/utilerias/Facturacion.vue`
        )
    },
    {
      path: "/verificador",
      meta: {
        public: true
      },
      name: "verificador",
      component: () =>
        import(
          /* webpackChunkName: "routes" */
          `@/views/utilerias/Verificador.vue`
        )
    },
    {
      path: "/precios/lista_precios",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_lista_precios",
      component: () =>
        import(
          `@/views/precios/ListaPrecios.vue`
        )
    },
    {
      path: "/reportes/stock_general",
      meta: {
        public: false,
        permissions: [
          {
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_stock_general",
      component: () =>
        import(
          `@/views/reportes/StockGeneral.vue`
        )
    },
    {
      path: "/reportes/movimientos_inventario",
      meta: {
        public: false,
        permissions: [
          {
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "movimientos_inventario",
      component: () =>
        import(
          `@/views/reportes/MovimientosInventario.vue`
        )
    },
    {
      path: "/reportes/stock_proveedores",
      meta: {
        public: false,
        permissions: [
          {
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_stock_proveedores",
      component: () =>
        import(
          `@/views/reportes/StockProveedores.vue`
        )
    },
    {
      path: "/reportes/ajustes_inventario",
      meta: {
        public: false,
        permissions: [
          {
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_ajustes_inventario",
      component: () =>
        import(
          `@/views/reportes/AjustesInventario.vue`
        )
    },
    {
      path: "/cortes/cortes_caja",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_cortes_caja",
      component: () =>
        import(
          `@/views/cortes/CortesCaja.vue`
        )
    },
    {
      path: "/cortes/sepsa",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "sepsa",
      component: () =>
        import(
          `@/views/cortes/ExcelBanco.vue`
        )
    },
    {
      path: "/reportes/ventas_totales",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_ventas_totales",
      component: () =>
        import(
          `@/views/reportes/VentasTotales.vue`
        )
    },
    {
      path: "/reportes/diferencias_ventas",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "diferencias_ventas",
      component: () =>
        import(
          `@/views/reportes/DiferenciasVentas.vue`
        )
    },
    {
      path: "/reportes/ventas_observaciones",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_ventas_observaciones",
      component: () =>
        import(
          `@/views/reportes/VentasObservaciones.vue`
        )
    },
    {
      path: "/reportes/ventas_articulo",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_ventas_articulo",
      component: () =>
        import(
          `@/views/reportes/VentasArticulo.vue`
        )
    },
    {
      path: "/reportes/ventas_devolucion",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_ventas_devolucion",
      component: () =>
        import(
          `@/views/reportes/VentasDevoluciones.vue`
        )
    },
    {
      path: "/reportes/ventas_diferencias_salida",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_ventas_diferencias_salida",
      component: () =>
        import(
          `@/views/reportes/VentasDiferenciasSalida.vue`
        )
    },
    {
      path: "/reportes/ventas_diferencias_devolucion",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_ventas_diferencias_devolucion",
      component: () =>
        import(
          `@/views/reportes/VentasDiferenciasDevolucion.vue`
        )
    },
    {
      path: "/reportes/utilidades_factura",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_utilidades_factura",
      component: () =>
        import(
          `@/views/reportes/UtilidadFacturas.vue`
        )
    },
    {
      path: "/reportes/utilidades_ventas",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_utilidades_ventas",
      component: () =>
        import(
          `@/views/reportes/UtilidadesVentas.vue`
        )
    },
    {
      path: "/modificar_utilidades",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "modificar_utilidades_ventas",
      component: () =>
        import(
          `@/views/reportes/ModificarUtilidadesVentas.vue`
        )
    },
    {
      path: "/reportes/utilidades_articulo",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_utilidades_articulo",
      component: () =>
        import(
          `@/views/reportes/UtilidadesArticulo.vue`
        )
    },
    {
      path: "/reportes/ventas_clientes",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_ventas_clientes",
      component: () =>
        import(
          `@/views/reportes/VentasClientes.vue`
        )
    },
    {
      path: "/reportes/ventas_empleados",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_ventas_empleados",
      component: () =>
        import(
          `@/views/reportes/VentasEmpleados.vue`
        )
    },
    {
      path: "/cotizacion",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "cotizacion",
      component: () =>
        import(
          `@/views/cotizaciones/cotizaciones.vue`
        )
    },
    {
      path: "/cortes/corte_total",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "corte_total",
      component: () =>
        import(
          `@/views/cortes/CorteTotal.vue`
        )
    },
    {
      path: "/retirar_caja",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "retirar_caja",
      component: () =>
        import(
          `@/views/cortes/RetirarCaja.vue`
        )
    },
    {
      path: "/recupera",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "recupera",
      component: () =>
        import(
          `@/views/cortes/RecuperarEliminados.vue`
        )
    },
    {
      path: "/cortes/resumen_ventas_empleado",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "resumen_ventas_empleados",
      component: () =>
        import(
          `@/views/cortes/ResumenVentasEmpleado.vue`
        )
    },
    {
      path: "/cortes/corte_general",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "cortes/corte_general",
      component: () =>
        import(
          `@/views/cortes/ConsultaCorteGeneral.vue`
        )
    },
    {
      path: "/consulta/ventas",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "consulta_ventas",
      component: () =>
        import(
          `@/views/ventas/ConsultarVentas.vue`
        )
    },
    {
      path: "/consulta/caratulas_folios",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "caratulas_folios",
      component: () =>
        import(
          `@/views/ventas/CaratulasFolios.vue`
        )
    },
    {
      path: "/ventas/modificar_cobro",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "modificar_cobro",
      component: () =>
        import(
          `@/views/ventas/ModificarTipoCobro.vue`
        )
    },
    {
      path: "/consulta/pedidos_online",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "pedidos_online",
      component: () =>
        import(
          `@/views/ventas/PedidosEnLinea.vue`
        )
    },
    {
      path: "/reportes/reporte_facturas",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_facturas",
      component: () =>
        import(
          `@/views/reportes/ReporteFacturasExcel.vue`
        )
    },
    {
      path: "/reportes/reporte_caducidad",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_caducidad",
      component: () =>
        import(
          `@/views/reportes/Caducidad.vue`
        )
    },
    {
      path: "/reportes/reporte_impuestos",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_impuestos",
      component: () =>
        import(
          `@/views/reportes/ReporteImpuestos.vue`
        )
    },
    {
      path: "/reportes/facturas",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "reporte_facturas2",
      component: () =>
        import(
          `@/views/reportes/ReporteFacturas.vue`
        )
    },
    {
      path: "/reportes/inventario",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "rpt_inventario",
      component: () =>
        import(
          `@/views/reportes/Inventario.vue`
        )
    },
    {
      path: "/reportes/compras_articulo",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "rpt_compras_articulo",
      component: () =>
        import(
          `@/views/reportes/ComprasArticulo.vue`
        )
    },
    {
      path: "/reportes/compras",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "rpt_compras_gral",
      component: () =>
        import(
          `@/views/reportes/Compras.vue`
        )
    },
    {
      path: "/comprasprecios",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "comprasprecios",
      component: () =>
        import(
          `@/views/compras/ComprasPrecios.vue`
        )
    },
    {
      path: "/reportes/excentas_gravadas",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "rpt_excentas_gravadas",
      component: () =>
        import(
          `@/views/reportes/ExcentasGravadas.vue`
        )
    },
    {
      path: "/facturas/facturas_globales",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "facturas_globales",
      component: () =>
        import(
          `@/views/reportes/FacturasGlobales.vue`
        )
    },
    {
      path: "/facturas/facturas_globales_robot",
      meta: {
        public: true,
      },
      name: "facturas_globales_robot",
      component: () =>
        import(
          `@/views/reportes/FacturasGlobalesRobot.vue`
        )
    },
    {
      path: "/limpiar",
      meta: {
        public: false,
        permissions: [{
            role: "guest",
            access: false,
            redirect: 'login'
          },
          {
            role: "sucursal",
            access: false,
            redirect: 'index'
          },
        ]
      },
      name: "limpiar",
      component: () =>
        import(
          `@/views/utilerias/Limpiar.vue`
        )
    },

  ]
});

router.beforeEach((to, from, next) => {
  window.urlreferrer = from.path;
  NProgress.start();
  next();
});

router.afterEach((to, from) => {
  store.state.current_url = to.path;
  NProgress.done();

});

export default router;
